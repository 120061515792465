import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import _ from 'underscore';
import { errorMsg, log } from 'utils';
import { url } from 'utils/url';

export const ANALYTICS_FETCH_DATA_INIT = createAction(
  'ANALYTICS_FETCH_DATA_INIT'
);
export const ANALYTICS_FETCH_DATA_SUCCESS = createAction(
  'ANALYTICS_FETCH_DATA_SUCCESS'
);
export const ANALYTICS_FETCH_PROFILE_SUCCESS = createAction(
  'ANALYTICS_FETCH_PROFILE_SUCCESS'
);
export const ANALYTICS_EXPORT_ANALYTIC_INIT = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_INIT'
);
export const ANALYTICS_EXPORT_ANALYTIC_SUCCESS = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_SUCCESS'
);
export const ANALYTICS_EXPORT_ANALYTIC_FAIL = createAction(
  'ANALYTICS_EXPORT_ANALYTIC_FAIL'
);
export const ANALYTICS_FETCH_DATA_FAIL = createAction(
  'ANALYTICS_FETCH_DATA_FAIL'
);
export const ANALYTICS_CALCULATE_DATA_INIT = createAction(
  'ANALYTICS_CALCULATE_DATA_INIT'
);
export const ANALYTICS_CALCULATE_DATA_SUCCESS = createAction(
  'ANALYTICS_CALCULATE_DATA_SUCCESS'
);
export const ANALYTICS_FETCH_LEADERBOARD_INIT = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_INIT'
);
export const ANALYTICS_FETCH_LEADERBOARD_SUCCESS = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_SUCCESS'
);
export const ANALYTICS_FETCH_LEADERBOARD_FAIL = createAction(
  'ANALYTICS_FETCH_LEADERBOARD_FAIL'
);

const cancelToken = axios.CancelToken.source();
export const fetchAnalytics = ({
  start,
  end,
  type,
  adsumScreenIds,
  locations,
  siteId,
}) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_FETCH_DATA_INIT());
    let analytic;
    try {
      if (type === 'trafficcounter') {
        analytic = await axios.post(
          `${url}/trafficAnalytic/analytics`,

          {
            start,
            end,
            siteId,
            location: locations,
            type: 'month',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      }
      else if (type === 'emotionanalytic') {
        analytic = await axios.post(
          `${url}/emotionAnalytic/analytics`,

          {
            start,
            end,
            siteId,
            location: locations,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      }
      else if (type === 'profileanalyzer') {
        analytic = await axios.post(
          `${url}/profileAnalytic/analytics`,

          {
            start,
            end,
            siteId,
            location: locations,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } else if (type === 'vehiclecounter') {
        analytic = await axios.post(
          `${url}/vehicleAnalytic/analytics`,

          {
            start,
            end,
            siteId,
            location: locations,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } else {
        analytic = await axios.post(
          `${url}/analytic/get`,

          {
            start,
            end,
            siteId,
            type,
            adsumScreenIds,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      }
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_DATA_FAIL({ error }));
    }

    log(analytic.data, 'analytic result');
    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      ANALYTICS_FETCH_DATA_SUCCESS({
        data: analytic.data.data,
        rawData: analytic.data.rawData,
        location: analytic.data.location,
      })
    );
  };
};

export const fetchLeaderboard = ({ start, end, siteId, adsumScreenIds }) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_FETCH_LEADERBOARD_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/getTop`,

        {
          start,
          end,
          siteId,
          adsumScreenIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(ANALYTICS_FETCH_LEADERBOARD_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      ANALYTICS_FETCH_LEADERBOARD_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};

export const calculateData = ({ data }) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_CALCULATE_DATA_INIT());

    console.log(data, 'data calculate');

    let result = [];

    const groupedDuration = _.groupBy(data, 'adsumScreenId');
    const keys = Object.keys(groupedDuration);

    for (const k of keys) {
      const content = groupedDuration[k];
      result.push({ x: k, y: content.length });
    }

    result = _.sortBy(result, 'y').reverse();
    console.log(result, 'sorted');

    return dispatch(
      ANALYTICS_CALCULATE_DATA_SUCCESS({
        rawData: result,
      })
    );
  };
};

export const downloadAnalyticsLeaderboard = (body) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_EXPORT_ANALYTIC_INIT());
    console.log(body, 'body');

    axios
      .post(`${url}/analytic/export`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `logs_export_${body.type}_${moment(new Date()).format(
            'YYYY-MM-DD'
          )}.csv`
        );
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(ANALYTICS_EXPORT_ANALYTIC_FAIL({ error }));
      });
  };
};

export const downloadAnalytics = (body) => {
  return async (dispatch) => {
    dispatch(ANALYTICS_EXPORT_ANALYTIC_INIT());
    if (body.type === 'leaderboard') {
      axios
        .post(`${url}/analytic/exportTop`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `logs_export_${body.type}_${moment(new Date()).format(
              'YYYY-MM-DD'
            )}.csv`
          );
          return dispatch(ANALYTICS_EXPORT_ANALYTIC_SUCCESS());
        })
        .catch((error) => {
          toastr.error('', `${errorMsg(dispatch, error)}`);
          return dispatch(ANALYTICS_EXPORT_ANALYTIC_FAIL({ error }));
        });
    } else {
      axios
        .post(`${url}/analytic/exportAll`, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(
            res.data,
            `logs_export_rawdata_${moment(new Date()).format('YYYY-MM-DD')}.csv`
          );
          return dispatch(ANALYTICS_EXPORT_ANALYTIC_SUCCESS());
        })
        .catch((error) => {
          toastr.error('', `${errorMsg(dispatch, error)}`);
          return dispatch(ANALYTICS_EXPORT_ANALYTIC_FAIL({ error }));
        });
    }
  };
};

export const cancelAction = () => {
  return async (dispatch, getState) => {
    try {
      cancelToken.cancel();
    } catch (error) {}

    return dispatch(
      ANALYTICS_FETCH_PROFILE_SUCCESS({
        data: {},
        location: [],
      })
    );
  };
};
export const loadingAnalytic = () => {
  return async (dispatch, getState) => {
    return dispatch(ANALYTICS_FETCH_DATA_INIT());
  };
};
