import { CircularProgress } from '@material-ui/core';
import { Table } from 'antd';
import { ManIcon, WomanIcon } from 'assets/icon';
import {
  ChartTitle,
  ChartWrapper,
  ReportWrapper,
} from 'components/Report/styles';
import React, { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
// import ScreensaverReport from './ScreensaverReport';

const ProfileAnalyzerReport = (props) => {
  const { analytics, type, loading, locations } = props;
  const INITIAL_STATE = {
    labels: [],
    datasets: [],
  };

  const [reportData, setReportData] = useState(INITIAL_STATE);

  const options = {
    events: [],
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    animation: {
      duration: 1,
      onComplete() {
        const chartInstance = this.chart;
        const { ctx } = chartInstance;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            if (dataset.data[index] > 0) {
              const data = dataset.data[index];
              const textwidth = ctx.measureText(data).width;
              ctx.fillStyle = bar._model.backgroundColor;
              ctx.font = 'bold 12pt roboto';
              ctx.fillText(data, bar._model.x - 20, bar._model.y);

              ctx.font = 'normal 12pt roboto';
              ctx.fillStyle = '#404040';
              ctx.fillText(' people', bar._model.x + textwidth, bar._model.y);
            }
          });
        });
      },
    },
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        boxWidth: 15,
        borderRadius: 10,
        useBorderRadius: true,
        fontStyle: 'bold',
      },
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      width: '10%',
      render: (text, record, index) => (
        <div className="table-name">{index + 1}</div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '40%',
      render: (text) => <div className="table-name">{text}</div>,
    },
    {
      title: 'Gender Ratio (Male : Female)',
      dataIndex: 'data',
      width: '20%',
      render: (text, record) => (
        <div className="table-name">
          <div className="male">
            {record.totalMale
              ? `(${Math.round(
                  (record.totalMale / record.totalPopulation) * 100
                )}%)  ${record.totalMale}`
              : '(0%) 0'}
          </div>
          :
          <div className="female">
            {record.totalFemale
              ? `${record.totalFemale}  (${Math.round(
                  (record.totalFemale / record.totalPopulation) * 100
                )}%)`
              : `0 (0%)`}
          </div>
        </div>
      ),
    },
  ];

  console.log(locations, 'locations');

  useEffect(() => {
    console.log(locations, 'analytics in report');
    if (analytics && Array.isArray(analytics) && !loading) {
      if (type === 'month') {
        const labels = analytics.map((data) =>
          data.high < 100
            ? `${data.low}-${data.high} Years Old`
            : `${data.low}+ Years Old`
        );
        const gender = ['Male', 'Female'];

        console.log(labels, 'labels');
        setReportData({
          labels,
          datasets: gender.map((set) => {
            return {
              label: set,
              data: analytics.map((data) => data[set.toLowerCase()]),
              borderWidth: 1,
              backgroundColor: set === 'Male' ? '#2C82F6' : '#FF7066',
              strokeColor: set === 'Male' ? '#2C82F6' : '#FF7066',
              borderRadius: 5,
              pointStyle: 'rectRounded',
            };
          }),
        });
        // let dataMonth = [];
        // dataMonth = locations.map((loc) => {
        //   return {
        //     location: loc.location,
        //     ratio: _.groupBy(loc.data, 'gender'),
        //   };
        // });
        // console.log(dataMonth, 'data month');
        // setDataTable(_.sortBy(dataMonth, ['location']));
      } else {
        const labels = analytics.map((data) => {
          const agerange =
            data.high < 100
              ? `${data.low}-${data.high} Years Old`
              : `${data.low}+ Years Old`;
          return `${agerange} (${data.name})`;
        });
        const gender = ['Male', 'Female'];

        setReportData({
          labels,
          datasets: gender.map((set) => {
            return {
              label: set,
              data: analytics.map((data) => data[set.toLowerCase()]),
              borderWidth: 1,
              backgroundColor:
                set.toLowerCase() === 'male' ? '#2C82F6' : '#FF7066',
              strokeColor: set.toLowerCase() === 'male' ? '#2C82F6' : '#FF7066',
              borderRadius: 5,
              pointStyle: 'rectRounded',
            };
          }),
        });
        // let dataMonth = [];
        // dataMonth = locations.map((loc) => {
        //   return {
        //     location: loc.location,
        //     ratio: _.groupBy(loc.data, 'gender'),
        //   };
        // });
        // console.log(dataMonth, 'data month');
        // setDataTable(_.sortBy(dataMonth, ['location']));
      }
    }
  }, [analytics]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="analytic-chart">
      {/* <div className="field-body"> */}

      {/* </div> */}
      <ReportWrapper>
        <ChartTitle>Profile Analyzer</ChartTitle>

        {/* <div className="selector">
          {locations && (
            <div className="m-2">
              <Select
                // defaultValue={loc}
                placeholder="Location"
                style={{ width: 120 }}
                onChange={(val) => {
                  setReportData();
                  setLoc(val);
                }}
                options={[
                  {
                    value: null,
                    label: 'All location',
                  },
                  ...locations.map((location) => {
                    return {
                      value: location,
                      label: location,
                    };
                  }),
                ]}
              />
            </div>
          )}
        </div> */}

        {/* <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{"Start Date"}</label>
          </div>
          <div className="field-label is-normal">
            <label className="label">{"End Date"}</label>
          </div>
        </div> */}
        {reportData && !loading ? (
          <ChartWrapper>
            <Bar data={reportData} width={100} height={250} options={options} />
          </ChartWrapper>
        ) : (
          <>
            <CircularProgress />
          </>
        )}
        <div className="gender-summary">
          {reportData &&
            reportData.datasets.map((data) => (
              <div
                className="male-summary"
                style={{
                  backgroundColor:
                    data.label.toLowerCase() === 'male' ? '#E6F0FE' : '#FFEEED',
                }}
              >
                <div
                  className="male-icon"
                  style={{
                    backgroundColor:
                      data.label.toLowerCase() === 'male'
                        ? '#2C82F6'
                        : '#FF7066',
                  }}
                >
                  {data.label.toLowerCase() === 'male' ? (
                    <ManIcon />
                  ) : (
                    <WomanIcon />
                  )}
                </div>
                <div className="male-data">
                  <h3
                    style={{
                      color:
                        data.label.toLowerCase() === 'male'
                          ? '#2C82F6'
                          : '#FF7066',
                    }}
                  >
                    {data.label.toLowerCase() === 'male' ? 'Male' : 'Female'}
                  </h3>
                  <p>{data.data.reduce((a, b) => a + b, 0)} People</p>
                </div>
              </div>
            ))}
        </div>
      </ReportWrapper>
      <Table columns={columns} dataSource={locations} />
    </div>
  );
};

export default ProfileAnalyzerReport;
